export enum PigeonStateEnum {
  Unknown,
  Active,
  Dead,
  Lost,
  Sold,
  Breeding, // Rozpłodowy
  Borrowed, // Pożyczony
  Widower, // Wdowiec
}
