import { Button, ButtonType } from "../../styles/Button.styled";
import HorizontalMenuItem, { HorizontalMenuProps } from "./IHorizontalMenu";
import {
  IoArrowForward,
  IoChevronBack,
  IoChevronForward,
} from "react-icons/io5";
import { useEffect, useRef, useState } from "react";

import { ButtonOpacity } from "../../styles/ButtonOpacity.styled";
import { HBox } from "../../styles/Flexbox.styled";
import Text from "../Text/Text";
import TextSizeEnum from "../../models/enums/TextSizeEnum";
import TextTypeEnum from "../../models/enums/TextTypeEnum";
import TextWeightEnum from "../../models/enums/TextWeightEnum";
import { useTheme } from "styled-components";

const HorizontalMenu = ({ items, onClick, active }: HorizontalMenuProps) => {
  const theme = useTheme();
  const ref = useRef<HTMLDivElement>(null);
  const [activeItem, setActiveItem] = useState(active);
  const [isRightScrollBtn, setIsRightScrollBtn] = useState(true);
  const [isLeftScrollBtn, setIsLeftScrollBtn] = useState(false);

  const handleClick = (item: HorizontalMenuItem) => {
    setActiveItem(item.id);
    if (onClick) {
      onClick(item);
    }
  };

  const scrollRight = () => {
    if (ref.current) {
      ref.current.scrollLeft += 100;
    }
  };

  const scrollLeft = () => {
    if (ref.current) {
      ref.current.scrollLeft -= 100;
    }
  };

  const onScroll = () => {
    setIsRightScrollBtn(
      ref.current
        ? ref.current.scrollWidth - ref.current.scrollLeft >=
            ref.current.clientWidth + 100
        : false
    );
    setIsLeftScrollBtn(ref.current ? ref.current.scrollLeft >= 200 : false);
  };

  useEffect(() => {
    setActiveItem(active);
    onScroll();
  }, [active]);
  return (
    <HBox position="relative" alignItems="center">
      <HBox
        ref={ref}
        overflowX="scroll"
        overflowY="hidden"
        alignItems="center"
        Padding="10px"
        backgroundColor={theme.palette.White}
        BorderRadius="10px"
        minHeight="52px"
        onScroll={onScroll}
      >
        {items.map((item, index) => (
          <ButtonOpacity onClick={() => handleClick(item)} key={item.id}>
            <HBox
              Padding="5px 10px"
              BorderRadius="10px"
              minWidth="150px"
              justifyContent="center"
              backgroundColor={
                item.id === activeItem
                  ? theme.palette.Primary1
                  : theme.palette.White
              }
            >
              <Text
                type={TextTypeEnum.body}
                weight={
                  item.id === activeItem
                    ? TextWeightEnum.medium
                    : TextWeightEnum.regular
                }
                textWrap="nowrap"
                size={TextSizeEnum.medium}
                color={theme.palette.Primary100}
              >
                {item.title}
              </Text>
            </HBox>
          </ButtonOpacity>
        ))}
      </HBox>
      <HBox position="absolute" Left="5px" visible={isLeftScrollBtn}>
        <Button
          onClick={scrollLeft}
          buttonType={ButtonType.Ghost}
          height="40px"
          padding="10px"
        >
          <IoChevronBack size={20} color={theme.palette.Grey50} />
        </Button>
      </HBox>
      <HBox position="absolute" Right="5px" visible={isRightScrollBtn}>
        <Button
          onClick={scrollRight}
          buttonType={ButtonType.Ghost}
          height="40px"
          padding="10px"
        >
          <IoChevronForward size={20} color={theme.palette.Grey50} />
        </Button>
      </HBox>
    </HBox>
  );
};

export default HorizontalMenu;
