import { authDelete, authGet, authPatch, authPost } from "./BaseService";

import BaseStatistics from "../models/BaseStatistics";
import ChildFilter from "../models/Filter/ChildFilter";
import { FilterName } from "../models/enums/FilterName";
import ParentFilter from "../models/Filter/ParentFilter";
import { ParentFilterOperator } from "../models/Filter/ParentFilterOperator";
import Pigeon from "../models/Pigeon";
import PigeonAchievement from "../models/PigeonAchievement";

export const getPigeons = async (filters?: string) => {
  let pigeons: object[] = [];
  if (!filters) {
    const data = await authGet<object[]>("pigeon");
    pigeons = data.data;
  } else {
    const data = await authGet<object[]>(
      `pigeon?filters=${encodeURIComponent(filters)}`
    );
    pigeons = data.data;
  }
  return pigeons.map((pigeon) => new Pigeon().fillData(pigeon));
};

export const getPigeon = async (id: number) => {
  const data = await authGet<object>(`pigeon/${id}`);
  return new Pigeon().fillData(data.data);
};

export const getPedigree = async (id: number, maxLevel: number) => {
  const data = await authGet<object>(`pigeon/pedigree/${id}/${maxLevel}`);
  return new Pigeon().fillData(data.data);
};

export const addPigeon = (pigeon: Pigeon) => {
  return authPost<object>("pigeon", pigeon);
};

export const updatePigeon = (pigeon: Pigeon) => {
  return authPatch<object>(`pigeon/${pigeon.id}`, pigeon);
};

export const removePigeon = (pigeon: Pigeon) => {
  return authDelete<object>(`pigeon/${pigeon.id}`);
};

export const setFather = (pigeon: Pigeon, father: Pigeon) => {
  return authPost<object>(`pigeon/${pigeon.id}/father`, {
    fatherId: father.id,
  });
};

export const setMother = (pigeon: Pigeon, mother: Pigeon) => {
  return authPost<object>(`pigeon/${pigeon.id}/mother`, {
    motherId: mother.id,
  });
};

export const removeFather = (pigeon: Pigeon) => {
  return authPost<object>(`pigeon/${pigeon.id}/unassign/father`, {});
};

export const removeMother = (pigeon: Pigeon) => {
  return authPost<object>(`pigeon/${pigeon.id}/unassign/mother`, {});
};

export const getAchievements = async (
  pigeon: Pigeon,
  useInPedigree?: boolean
) => {
  let childFilters: ChildFilter[] = [];
  childFilters.push(new ChildFilter(FilterName.PigeonId, pigeon.id));
  if (useInPedigree !== undefined) {
    childFilters.push(new ChildFilter(FilterName.UseInPedigree, useInPedigree));
  }
  const filter = new ParentFilter(childFilters, ParentFilterOperator.And);
  const result = await authGet<object[]>(
    "pigeon-achievement?filters=" + filter.transform()
  );
  return result.data.map((achievement) =>
    new PigeonAchievement().fillData(achievement)
  );
};

export const addAchievement = (achievement: PigeonAchievement) => {
  return authPost<object>("pigeon-achievement", achievement);
};

export const updateAchievement = (achievement: PigeonAchievement) => {
  return authPatch<object>(`pigeon-achievement/${achievement.id}`, achievement);
};

export const removeAchievement = (achievement: PigeonAchievement) => {
  return authDelete<object>(`pigeon-achievement/${achievement.id}`);
};

export const getPigeonsStatistics = async () => {
  const result = await authGet<object>("pigeon/statistics");
  return BaseStatistics.create(result.data);
};

export const getUserPigeonsStatistics = async (userId: number) => {
  const result = await authGet<object>(`pigeon/statistics/${userId}`);
  return BaseStatistics.create(result.data);
};
