import { HBox, VBox } from "../../styles/Flexbox.styled";

import { BreadcrumbProps } from "./IBreadcrumb";
import { Link } from "react-router-dom";
import Text from "../Text/Text";
import TextSizeEnum from "../../models/enums/TextSizeEnum";
import TextTypeEnum from "../../models/enums/TextTypeEnum";
import TextWeightEnum from "../../models/enums/TextWeightEnum";
import { useTheme } from "styled-components";

const Breadcrumb = ({ items, current, onClick }: BreadcrumbProps) => {
  const theme = useTheme();
  const separator = <span>&gt;</span>;

  return (
    <HBox
      overflowX="scroll"
      overflowY="hidden"
      BorderRadius="10px"
      backgroundColor={theme.palette.White}
      alignItems="center"
      Padding="5px"
    >
      {items.map((item, index) => (
        <HBox
          alignItems="center"
          key={`${item.path}:${Math.random() * 1000000}`}
        >
          <HBox Padding="5px">
            <Link to={item.path} onClick={() => onClick && onClick(item)}>
              <Text
                color={theme.palette.Primary100}
                weight={TextWeightEnum.regular}
                size={TextSizeEnum.medium}
                type={TextTypeEnum.body}
              >
                {item.title}
              </Text>
            </Link>
          </HBox>
          <Text
            color={theme.palette.Grey50}
            weight={TextWeightEnum.regular}
            size={TextSizeEnum.small}
            type={TextTypeEnum.body}
          >
            {index < items.length - 1 ? separator : ""}
          </Text>
        </HBox>
      ))}
      <Text
        color={theme.palette.Grey50}
        weight={TextWeightEnum.regular}
        size={TextSizeEnum.small}
        type={TextTypeEnum.body}
      >
        {separator}
      </Text>
      <HBox Padding="5px">
        <Text
          color={theme.palette.Grey50}
          weight={TextWeightEnum.regular}
          size={TextSizeEnum.medium}
          type={TextTypeEnum.body}
        >
          {current.title}
        </Text>
      </HBox>
    </HBox>
  );
};

export default Breadcrumb;
