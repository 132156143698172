import { HBox, VBox } from "../../styles/Flexbox.styled";
import { useEffect, useState } from "react";

import Checkbox from "../Checkbox";
import { ChildFilterType } from "../../models/Filter/ChildFilterType";
import { ChildFilterValue } from "../../models/Filter/ChildFilterValue";
import { FilterProps } from "./IFilter";
import TextInput from "../TextInput";

const Filter = ({ filter, onFilterChange }: FilterProps) => {
  const [stateFilter, setStateFilter] = useState(filter);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    setStateFilter(filter);
  }, [filter.Name, filter.Value]);

  const onChange = (value: ChildFilterValue) => {
    stateFilter.Value = value;
    setStateFilter(stateFilter);
    setRefresh(!refresh);
    onFilterChange(stateFilter);
  };

  switch (stateFilter.Type) {
    case ChildFilterType.Text: {
      return (
        <HBox width="100%">
          <TextInput
            placeholder={stateFilter.Placeholder || ""}
            value={stateFilter.Value}
            onChange={(value: string) => {
              onChange(value);
            }}
          >
            {filter.DisplayName}
          </TextInput>
        </HBox>
      );
    }
    case ChildFilterType.Number: {
      return (
        <HBox width="100%">
          <TextInput
            value={stateFilter.Value}
            placeholder={stateFilter.Placeholder || ""}
            type="number"
            onChange={(value: string) => {
              onChange(Number(value));
            }}
          >
            {filter.DisplayName}
          </TextInput>
        </HBox>
      );
    }
    case ChildFilterType.Boolean: {
      return (
        <VBox width="100%">
          <Checkbox
            state={stateFilter.Value as boolean}
            title={stateFilter.DisplayName}
            onClick={() => {
              onChange(!stateFilter.Value);
            }}
          />
        </VBox>
      );
    }
  }
  return null;
};

export default Filter;
