import { Base } from "./Base";
import PageOptions from "./PageOptions";

/**
 * The `PageMeta` class is used to encapsulate metadata for paginated results.
 * It includes information about the current page, the total number of items, the total number of pages, and whether there are previous or next pages.
 *
 * @class PageMeta
 * @param {PageMetaParameters} parameters - An object containing a `PageOptionsDto` object and the total number of items.
 *
 * @property {number} page - The current page number.
 * @property {number} take - The number of items per page.
 * @property {number} total - The total number of items.
 * @property {number} pages - The total number of pages.
 * @property {boolean} isPrev - A boolean indicating whether there is a previous page.
 * @property {boolean} isNext - A boolean indicating whether there is a next page.
 *
 * @example
 * const pageOptionsDto = new PageOptionsDto(1, 10);
 * const total = 100;
 * const pageMeta = new PageMeta({ pageOptionsDto, total });
 * console.log(pageMeta);
 * // output: { page: 1, take: 10, total: 100, pages: 10, isPrev: false, isNext: true }
 */
export default class PageMeta extends Base {
  page!: number;
  take!: number;
  total!: number;
  pages!: number;
  isPrev!: boolean;
  isNext!: boolean;
}
