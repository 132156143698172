import ChildFilter from "../../../models/Filter/ChildFilter";
import { ChooseMotherProps } from "./IChooseMother";
import ChoosePigeon from "./ChoosePigeon";
import { FilterName } from "../../../models/enums/FilterName";
import ParentFilter from "../../../models/Filter/ParentFilter";
import { ParentFilterOperator } from "../../../models/Filter/ParentFilterOperator";
import { PigeonSexEnum } from "../../../models/enums/PigeonSexEnum";

const ChooseChild = (props: ChooseMotherProps) => {
  const childFilter =
    props.pigeon.sex === PigeonSexEnum.Male
      ? new ChildFilter(FilterName.FatherIdNull)
      : new ChildFilter(FilterName.MotherIdNull);
  return (
    <ChoosePigeon
      isVisible={props.isVisible}
      onConfirm={props.onConfirm}
      onClose={props.onClose}
      title="Choose child"
      description="Choose a child for the pigeon"
      initFilter={new ParentFilter([childFilter], ParentFilterOperator.And)}
    />
  );
};
export default ChooseChild;
