import { HBox, VBox } from "../../styles/Flexbox.styled";

import HighligtedText from "../HighlightedText/HighlightedText";
import Text from "../Text/Text";
import TextSizeEnum from "../../models/enums/TextSizeEnum";
import TextTypeEnum from "../../models/enums/TextTypeEnum";
import TextWeightEnum from "../../models/enums/TextWeightEnum";
import { getText } from "../../locales/initI18n";
import { useTheme } from "styled-components";

const FourthNews = () => {
  const theme = useTheme();
  return (
    <VBox width="100%">
      <HBox justifyContent="flex-end">
        <HighligtedText
          text={getText("FourthNews.PublicationDate")}
          backgroundColor={theme.palette.Yellow100}
        />
      </HBox>
      <VBox>
        <Text
          type={TextTypeEnum.h6}
          size={TextSizeEnum.large}
          weight={TextWeightEnum.medium}
          color={theme.palette.Grey90}
        >
          {getText("FourthNews.ComputerVersionTitle")}
        </Text>
        <Text
          type={TextTypeEnum.body}
          size={TextSizeEnum.medium}
          weight={TextWeightEnum.regular}
          color={theme.palette.Grey70}
          textAlign="justify"
        >
          {getText("FourthNews.ComputerVersionDescription")}
        </Text>
      </VBox>
    </VBox>
  );
};

export default FourthNews;
