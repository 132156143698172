import { OrderEnum } from "./enums/OrderEnum";

export default class PageOptions {
  order: OrderEnum = OrderEnum.ASC;
  page: number = 1;
  take: number = 10;

  get skip(): number {
    return (this.page - 1) * this.take;
  }

  constructor(page?: number, take?: number, order?: OrderEnum) {
    this.page = page || this.page;
    this.take = take || this.take;
    this.order = order || this.order;
  }

  transform() {
    return `page=${this.page}&take=${this.take}&order=${this.order}`;
  }
}
