import {
  AddPigeonAchievementFormData,
  AddPigeonAchievementProps,
} from "./IAddPigeonAchievement";
import { Button, ButtonType } from "../../../styles/Button.styled";
import { Field, Form, FormInstance } from "houseform";
import { memo, useRef } from "react";

import Checkbox from "../../Checkbox";
import HalfModal from "../../HalfModal/HalfModal";
import PigeonAchievement from "../../../models/PigeonAchievement";
import TextFormInput from "../../Form/TextFormInput";
import { TextInputType } from "../../../styles/ITextInput";
import { VBox } from "../../../styles/Flexbox.styled";
import { getText } from "../../../locales/initI18n";
import { useTheme } from "styled-components";

const AddPigeonAchievement = (props: AddPigeonAchievementProps) => {
  const form = useRef<FormInstance<AddPigeonAchievementFormData>>(null);
  const theme = useTheme();

  const onSubmit = async (data: AddPigeonAchievementFormData) => {
    const achievement = new PigeonAchievement().fillData(data);
    if (props.achievement) achievement.id = props.achievement.id;
    props.onConfirm(achievement);
  };

  return (
    <HalfModal
      visible={props.isVisible}
      onClose={props.onClose}
      title={getText("AddPigeonAchievementForm.modal.title")}
      description={getText("AddPigeonAchievementForm.modal.description")}
      heightDivider={1.5}
    >
      <VBox>
        <Form onSubmit={onSubmit} ref={form}>
          {({ submit }) => (
            <VBox Padding="0px 20px 0 20px" gap="10px">
              <Field<string>
                name="name"
                initialValue={props?.achievement?.name || ""}
              >
                {(props) => {
                  return (
                    <TextFormInput
                      title={getText(
                        "AddPigeonAchievementForm.form.name.title"
                      )}
                      placeholder={getText(
                        "AddPigeonAchievementForm.form.name.placehloder"
                      )}
                      {...props}
                    />
                  );
                }}
              </Field>
              <Field<string>
                name="year"
                initialValue={props?.achievement?.year.toString() || ""}
              >
                {(props) => {
                  return (
                    <TextFormInput
                      type="number"
                      title={getText(
                        "AddPigeonAchievementForm.form.year.title"
                      )}
                      placeholder={getText(
                        "AddPigeonAchievementForm.form.year.placeholder"
                      )}
                      {...props}
                    />
                  );
                }}
              </Field>
              <Field<string>
                name="remarks"
                initialValue={props?.achievement?.remarks}
              >
                {(props) => {
                  return (
                    <TextFormInput
                      inputType={TextInputType.TextArea}
                      title={getText(
                        "AddPigeonAchievementForm.form.description.title"
                      )}
                      placeholder={getText(
                        "AddPigeonAchievementForm.form.description.placeholder"
                      )}
                      {...props}
                    />
                  );
                }}
              </Field>
              <Field<boolean>
                name="useInPedigree"
                initialValue={props?.achievement?.useInPedigree || true}
              >
                {(props) => {
                  return (
                    <Checkbox
                      title={getText(
                        "AddPigeonAchievementForm.form.useInPedigree.title"
                      )}
                      state={props.value}
                      onClick={() => props.setValue(!props.value)}
                      {...props}
                    />
                  );
                }}
              </Field>
              <Button
                onClick={submit}
                buttonType={ButtonType.Primary}
                width="100%"
              >
                {getText("AddPigeonAchievementForm.form.addButton.title")}
              </Button>
            </VBox>
          )}
        </Form>
      </VBox>
    </HalfModal>
  );
};

export default memo((props: AddPigeonAchievementProps) =>
  props.isVisible ? <AddPigeonAchievement {...props} /> : null
);
