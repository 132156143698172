import { ActiveFiltersProps } from "./IActiveFilters";
import ChildFilter from "../../../models/Filter/ChildFilter";
import HighligtedText from "../../HighlightedText/HighlightedText";
import { IBaseFilter } from "../../../models/Filter/IBaseFilter";
import IChildFilter from "../../../models/Filter/IChildFilter";
import ParentFilter from "../../../models/Filter/ParentFilter";

const ActiveFilters = ({ filters }: ActiveFiltersProps) => {
  const createTextInternal = (filter: IChildFilter) => {
    if (typeof filter.Value === "string" && filter.Value.length === 0)
      return null;
    if (filter.isBoolean())
      return <HighligtedText key={filter.Name} text={filter.DisplayName} />;
    if (filter.isText())
      return (
        <HighligtedText
          key={filter.Name}
          text={(filter.Value || "Pusty").toString()}
        />
      );
    if (filter.isNumber())
      return (
        <HighligtedText
          key={filter.Name}
          text={(filter.Value || "Pusty").toString()}
        />
      );
  };
  const createText = (filter: IBaseFilter) => {
    if (ChildFilter.is(filter)) return createTextInternal(filter);
    if (ParentFilter.is(filter)) {
      const childs = filter.getChilds();
      return childs.map((child) => createTextInternal(child));
    }
    return null;
  };
  return (
    <>
      {filters.map((filter, index) => {
        if (index > 2) return null;
        if (index === 2) return <HighligtedText key={filter.Name} text="..." />;
        return createText(filter);
      })}
    </>
  );
};

export default ActiveFilters;
