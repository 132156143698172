import {
  Breadcrumbs,
  BreadcrumbsItem,
  Button,
  DynamicPage,
  DynamicPageHeader,
  DynamicPageTitle,
  Label,
  Tab,
  TabContainer,
  Table,
  TableCell,
  TableGrowing,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
  Tag,
  Title,
  ToggleButton,
  Toolbar,
  ToolbarButton,
} from "@ui5/webcomponents-react";
import { HBox, ResponsiveHBox } from "../styles/Flexbox.styled";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import ChildFilter from "../models/Filter/ChildFilter";
import Debounce from "../utils/Debounce";
import { FilterName } from "../models/enums/FilterName";
import { FlightFilterEnum } from "../models/enums/FlightFilterEnum";
import { FlightTimeFliterEnum } from "../models/enums/FlightTimeFliterEnum";
import Formatter from "../utils/Formatter";
import ParentFilter from "../models/Filter/ParentFilter";
import { ParentFilterOperator } from "../models/Filter/ParentFilterOperator";
import TextInput from "../components/TextInput";
import { breakpoints } from "../styles/Devices";
import { getText } from "../locales/initI18n";
import useFlights from "../hooks/useFlights";
import { useTheme } from "styled-components";
import useUser from "../hooks/useUser";
import withNavbar from "../hoc/withNavbar/withNavbar";

const FlightList = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [rawFilters, setRawFilters] = useState<string>("");
  const [tab, setTab] = useState<FlightFilterEnum>(FlightFilterEnum.Private);
  const [timeFilter, setTimeFilter] = useState<FlightTimeFliterEnum>(
    FlightTimeFliterEnum.All
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState<string>("");
  const { data: user } = useUser();
  const { queryFlights, count } = useFlights(tab, rawFilters);
  const onSearchChange = (e: string) => {
    setSearch(e);

    debounce.current.exec(e);
  };

  const handleSearchChange = (value: string) => {
    const fliter = new ParentFilter([], ParentFilterOperator.And);
    setSearchParams({
      search: value,
      timeFilter: timeFilter.toString(),
      tab: tab.toString(),
    });
    if (value) {
      const searchFilter = new ChildFilter(FilterName.Name, value);
      fliter.addFilter(searchFilter);
    }
    if (timeFilter === FlightTimeFliterEnum.Future) {
      const first = new ChildFilter(FilterName.FutureFlight);
      fliter.addFilter(first);
    }
    if (timeFilter === FlightTimeFliterEnum.History) {
      const first = new ChildFilter(FilterName.HistoryFlight);
      fliter.addFilter(first);
    }
    if (timeFilter === FlightTimeFliterEnum.Present) {
      const first = new ChildFilter(FilterName.PresentFlightStart);
      const second = new ChildFilter(FilterName.PresentFlightEnd);
      fliter.addFilter(
        new ParentFilter([first, second], ParentFilterOperator.And)
      );
    }

    if (fliter.getChilds().length === 0) setRawFilters("");
    else setRawFilters(fliter.transform());
  };
  const debounce = useRef<Debounce>(new Debounce(handleSearchChange, 500));
  const flights = queryFlights.data?.pages.flatMap((page) => page.data);
  const lastMeta =
    queryFlights.data?.pages[queryFlights.data.pages.length - 1].meta;

  useEffect(() => {
    handleSearchChange(search);
  }, [timeFilter, tab]);

  useEffect(() => {
    if (searchParams.has("search")) setSearch(searchParams.get("search") || "");
    if (searchParams.has("timeFilter"))
      setTimeFilter(Number(searchParams.get("timeFilter")));
    if (searchParams.has("tab"))
      setTab(searchParams.get("tab") as FlightFilterEnum);
    queryFlights.refetch();
  }, []);
  return (
    <DynamicPage
      style={{
        width: "100%",
      }}
      headerArea={
        <DynamicPageHeader>
          <HBox width="300px">
            <TextInput
              onChange={onSearchChange}
              value={search}
              placeholder="Torun"
              width="100%"
            >
              {getText("FlightList.Search")}
            </TextInput>
          </HBox>
        </DynamicPageHeader>
      }
      titleArea={
        <DynamicPageTitle
          actionsBar={
            <Toolbar design="Transparent">
              <ToolbarButton
                onClick={() => navigate("/dashboard/list/flights/0")}
                design="Emphasized"
                text={getText("AddFlightForm.AddFlight")}
              />
              {search && (
                <ToolbarButton
                  onClick={() => {
                    setSearch("");
                    handleSearchChange("");
                  }}
                  text={getText("FlightList.ClearSearch")}
                />
              )}
            </Toolbar>
          }
          breadcrumbs={
            <Breadcrumbs>
              <BreadcrumbsItem href="/dashboard/map">
                {getText("FlightList.breadbrumb.Map")}
              </BreadcrumbsItem>
              <BreadcrumbsItem>
                {getText("FlightList.breadbrumb.FlightList")}
              </BreadcrumbsItem>
            </Breadcrumbs>
          }
          heading={<Title>{getText("FlightList.FlightList")}</Title>}
          subheading={
            <Label>
              {Formatter.getFlightTableContentName(tab, timeFilter, search)}
            </Label>
          }
          snappedSubheading={
            <Label>
              {Formatter.getFlightTableContentName(tab, timeFilter, search)}
            </Label>
          }
          snappedHeading={<Title>{getText("FlightList.FlightList")}</Title>}
          navigationBar={
            <Toolbar design="Transparent">
              <ToolbarButton
                onClick={() => navigate(-1)}
                design="Transparent"
                icon="decline"
              />
            </Toolbar>
          }
        ></DynamicPageTitle>
      }
    >
      <ResponsiveHBox gap="5px" Margin="0 0 5px 0">
        <ToggleButton
          pressed={timeFilter === FlightTimeFliterEnum.All}
          icon="list"
          onClick={() => setTimeFilter(FlightTimeFliterEnum.All)}
        >
          {breakpoints.isPhone() ? "" : getText("FlightList.TimeFilterAll")}
        </ToggleButton>
        <ToggleButton
          pressed={timeFilter === FlightTimeFliterEnum.Present}
          icon="present"
          onClick={() => setTimeFilter(FlightTimeFliterEnum.Present)}
        >
          {breakpoints.isPhone() ? "" : getText("FlightList.TimeFilterPresent")}
        </ToggleButton>
        <ToggleButton
          pressed={timeFilter === FlightTimeFliterEnum.Future}
          icon="future"
          onClick={() => setTimeFilter(FlightTimeFliterEnum.Future)}
        >
          {breakpoints.isPhone() ? "" : getText("FlightList.TimeFilterFuture")}
        </ToggleButton>
        <ToggleButton
          pressed={timeFilter === FlightTimeFliterEnum.History}
          icon="history"
          onClick={() => setTimeFilter(FlightTimeFliterEnum.History)}
        >
          {breakpoints.isPhone() ? "" : getText("FlightList.TimeFilterHistory")}
        </ToggleButton>
      </ResponsiveHBox>
      <TabContainer
        collapsed={true}
        onTabSelect={(event) => {
          const index = event.detail.tabIndex;
          if (index === 0) setTab(FlightFilterEnum.Private);
          if (index === 1) setTab(FlightFilterEnum.Open);
          if (index === 2) setTab(FlightFilterEnum.Shared);
          if (index === 3) setTab(FlightFilterEnum.InternalOpen);
        }}
      >
        <Tab
          icon="locked"
          selected={tab === FlightFilterEnum.Private}
          text={getText("FlightList.tab.private")}
        ></Tab>
        <Tab
          icon="globe"
          additionalText={`Możesz dołączyć: ${count.events || 0}`}
          selected={tab === FlightFilterEnum.Open}
          text={getText("FlightList.tab.event")}
        ></Tab>
        <Tab
          icon="share-2"
          additionalText={`Nowe: ${count.shared}`}
          selected={tab === FlightFilterEnum.Shared}
          text={getText("FlightList.TypeFilterFriends")}
        ></Tab>
        <Tab
          icon="permission"
          additionalText={`Możesz dołączyć: ${count.private}`}
          selected={tab === FlightFilterEnum.InternalOpen}
          text={getText("FlightList.TypeFilterGroup")}
        ></Tab>
      </TabContainer>
      <Table
        overflowMode="Popin"
        loading={queryFlights.isLoading}
        headerRow={[
          <TableHeaderRow>
            <TableHeaderCell maxWidth="30px"></TableHeaderCell>
            <TableHeaderCell minWidth="200px">
              <span>{getText("FlightList.tableColumn.flightName")}</span>
            </TableHeaderCell>
            <TableHeaderCell minWidth="150px">
              <span>{getText("FlightList.tableColumn.startDate")}</span>
            </TableHeaderCell>
            <TableHeaderCell minWidth="100px">
              <span>{getText("FlightList.tableColumn.action")}</span>
            </TableHeaderCell>
          </TableHeaderRow>,
        ]}
        onRowClick={(event) => {
          const key = event.detail.row.rowKey;
          navigate({
            pathname: `/dashboard/list/flights/${key}`,
            search: searchParams.toString(),
          });
        }}
      >
        {lastMeta?.isNext && (
          <TableGrowing
            type="Button"
            growing-text="More"
            slot="features"
            onLoadMore={() => queryFlights.fetchNextPage()}
          ></TableGrowing>
        )}
        {flights &&
          flights?.map((flight) => {
            flight.tab = tab;
            return (
              <TableRow
                rowKey={flight.id.toString()}
                interactive
                id={flight.id.toString()}
                key={flight.id}
              >
                <TableCell>
                  <HBox
                    width="100%"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <HBox
                      width="10px"
                      height="10px"
                      BorderRadius="100%"
                      backgroundColor={
                        flight.isHistory()
                          ? theme.palette.Red80
                          : flight.isPresent()
                          ? theme.palette.Green70
                          : theme.palette.Primary70
                      }
                    />
                  </HBox>
                </TableCell>
                <TableCell>{flight.name}</TableCell>
                <TableCell>{Formatter.DateTime(flight.startDate)}</TableCell>
                <TableCell>
                  <HBox gap="5px">
                    <Button
                      onClick={() => navigate(`/dashboard/map/${flight.id}`)}
                      icon="map-3"
                    >
                      {getText("FlightList.action.showOnMap")}
                    </Button>
                    {user && flight.isJoinable(user) && (
                      <Button
                        icon="select-appointments"
                        design="Positive"
                        onClick={() =>
                          navigate(
                            {
                              pathname: `/dashboard/list/flights/${flight.id}`,
                              search: searchParams.toString(),
                            },
                            {
                              state: { isJoin: true },
                            }
                          )
                        }
                      >
                        {getText("AddFlightForm.JoinFlight")}
                      </Button>
                    )}
                  </HBox>
                </TableCell>
              </TableRow>
            );
          })}
      </Table>
    </DynamicPage>
  );
};

export default withNavbar(FlightList);
