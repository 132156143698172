import { ModalProps } from "../IModalOptions";
import Pigeon from "../../../models/Pigeon";
import SelectOption from "../../../models/SelectOption";

export interface AddPigeonFormProps extends ModalProps<Pigeon, undefined> {
  pigeon?: Pigeon;
  isEdit?: boolean;
}

export enum AddPigeonFormModalEnum {
  None,
  Friends,
  OtherBreederHelp,
}

export interface AddPigeonFormData {
  ring: string;
  color: SelectOption;
  sex: SelectOption;
  ownerName: string;
  state: SelectOption;
  breed: string;
  eyeColor: string;
  hatchingDate: Date | null;
  year: number | null;
}
