import { HBox, VBox } from "../../styles/Flexbox.styled";
import {
  IoDocumentTextOutline,
  IoMapOutline,
  IoPeopleOutline,
  IoPersonOutline,
} from "react-icons/io5";
import { Link, useLocation } from "react-router-dom";
import { breakpoints, devices } from "../../styles/Devices";

import { ComponentType } from "react";
import { IoMdPin } from "react-icons/io";
import Text from "../../components/Text/Text";
import { getText } from "../../locales/initI18n";
import { useTheme } from "styled-components";

function withNavbar<T extends {}>(Component: ComponentType<T>) {
  return (props: T) => {
    const theme = useTheme();
    const location = useLocation();
    const isCurrentTab = (tab: string) => location.pathname.includes(tab);
    const getTextColor = (tab: string) => {
      if (isCurrentTab(tab)) return theme.palette.Primary100;
      return theme.palette.Grey80;
    };
    const getIndicatorColor = (tab: string) => {
      if (isCurrentTab(tab)) return theme.palette.Primary40;
      return theme.palette.White;
    };

    const containerPadding = breakpoints.isPhone()
      ? "0 0 80px 0"
      : "60px 0 0 0";

    return (
      <VBox
        Padding={containerPadding}
        position="relative"
        height="100%"
        width="100%"
        overflowY="hidden"
        shadow="medium"
      >
        <HBox
          position="absolute"
          visible={!breakpoints.isPhone()}
          Top="0"
          width="100%"
          height="60px"
          justifyContent="flex-start"
          alignItems="center"
          Padding="0 20px"
          backgroundColor={theme.palette.White}
          shadow="medium"
          zIndex={1000}
        >
          <img
            alt="Pigeon Map logo"
            src="/assets/android/logo192.png"
            width="40px"
          />
          <Link to="/dashboard/map">
            <HBox
              position="relative"
              alignItems="center"
              gap="5px"
              Margin="0 0 0 20px"
              border={`2px solid ${getIndicatorColor("map")}`}
              BorderRadius="5px"
              Padding="5px 10px"
            >
              <IoMapOutline size={20} color={getTextColor("map")} />
              <Text
                type="body"
                size="small"
                weight="regular"
                color={getTextColor("map")}
              >
                {getText("Map")}
              </Text>
            </HBox>
          </Link>
          <Link to="/dashboard/list/flights">
            <HBox
              position="relative"
              alignItems="center"
              gap="5px"
              Margin="0 0 0 5px"
              border={`2px solid ${getIndicatorColor("list/flights")}`}
              BorderRadius="5px"
              Padding="5px 10px"
            >
              <IoMdPin size={20} color={getTextColor("list/flights")} />
              <Text
                type="body"
                size="small"
                weight="regular"
                color={getTextColor("list/flights")}
              >
                {getText("Map.OptionFlights")}
              </Text>
            </HBox>
          </Link>
          <Link to="/dashboard/pigeons">
            <HBox
              position="relative"
              alignItems="center"
              gap="5px"
              Margin="0 0 0 5px"
              border={`2px solid ${getIndicatorColor("pigeons")}`}
              BorderRadius="5px"
              Padding="5px 10px"
            >
              <IoDocumentTextOutline
                size={20}
                color={getTextColor("pigeons")}
              />
              <Text
                type="body"
                size="small"
                weight="regular"
                color={getTextColor("pigeons")}
              >
                {getText("Notes")}
              </Text>
            </HBox>
          </Link>
          <Link to="/dashboard/farmers">
            <HBox
              position="relative"
              alignItems="center"
              gap="5px"
              Margin="0 0 0 5px"
              border={`2px solid ${getIndicatorColor("farmers")}`}
              BorderRadius="5px"
              Padding="5px 10px"
            >
              <IoPeopleOutline size={20} color={getTextColor("farmers")} />
              <Text
                type="body"
                size="small"
                weight="regular"
                color={getTextColor("farmers")}
              >
                {getText("General.Breeders")}
              </Text>
            </HBox>
          </Link>
          <Link to="/dashboard/profile">
            <HBox
              position="relative"
              alignItems="center"
              gap="5px"
              Margin="0 0 0 5px"
              border={`2px solid ${getIndicatorColor("profile")}`}
              BorderRadius="5px"
              Padding="5px 10px"
            >
              <IoPersonOutline size={20} color={getTextColor("profile")} />
              <Text
                type="body"
                size="small"
                weight="regular"
                color={getTextColor("profile")}
              >
                {getText("Profile")}
              </Text>
            </HBox>
          </Link>
        </HBox>
        <Component {...props} />
        <HBox
          visible={breakpoints.isPhone()}
          position="absolute"
          Bottom="0"
          width="100%"
          height="80px"
          justifyContent="space-around"
          alignItems="center"
          Padding="0 0px"
          backgroundColor={theme.palette.White}
          shadow="small"
          zIndex={1}
        >
          <Link
            to="/dashboard/map"
            style={{ height: "100%", width: "25%", textDecoration: "none" }}
          >
            <VBox
              position="relative"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <IoMapOutline size={30} color={getTextColor("map")} />
              <Text
                type="body"
                size="medium"
                weight="regular"
                color={getTextColor("map")}
              >
                {getText("Map")}
              </Text>
              <HBox
                position="absolute"
                Bottom="-5px"
                height="20px"
                width="40px"
                BorderRadius="10px 10px 0 0"
                backgroundColor={getIndicatorColor("map")}
              />
            </VBox>
          </Link>
          <Link
            to="/dashboard/pigeons"
            style={{ height: "100%", width: "25%", textDecoration: "none" }}
          >
            <VBox
              position="relative"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <IoDocumentTextOutline
                size={30}
                color={getTextColor("pigeons")}
              />
              <Text
                type="body"
                size="medium"
                weight="regular"
                color={getTextColor("pigeons")}
              >
                {getText("Notes")}
              </Text>
              <HBox
                position="absolute"
                Bottom="-5px"
                height="20px"
                width="40px"
                BorderRadius="10px 10px 0 0"
                backgroundColor={getIndicatorColor("pigeons")}
              />
            </VBox>
          </Link>
          <Link
            to="/dashboard/farmers"
            style={{ height: "100%", width: "25%", textDecoration: "none" }}
          >
            <VBox
              position="relative"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <IoPeopleOutline size={30} color={getTextColor("farmers")} />
              <Text
                type="body"
                size="medium"
                weight="regular"
                color={getTextColor("farmers")}
              >
                {getText("General.Breeders")}
              </Text>
              <HBox
                position="absolute"
                Bottom="-5px"
                height="20px"
                width="40px"
                BorderRadius="10px 10px 0 0"
                backgroundColor={getIndicatorColor("farmers")}
              />
            </VBox>
          </Link>
          <Link
            to="/dashboard/profile"
            style={{ height: "100%", width: "25%", textDecoration: "none" }}
          >
            <VBox
              position="relative"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <IoPersonOutline size={30} color={getTextColor("profile")} />
              <Text
                type="body"
                size="medium"
                weight="regular"
                color={getTextColor("profile")}
              >
                {getText("Profile")}
              </Text>
              <HBox
                position="absolute"
                Bottom="-5px"
                height="20px"
                width="40px"
                BorderRadius="10px 10px 0 0"
                backgroundColor={getIndicatorColor("profile")}
              />
            </VBox>
          </Link>
        </HBox>
      </VBox>
    );
  };
}

export default withNavbar;
