import { HBox, VBox } from "../styles/Flexbox.styled";
import { IoPaperPlaneOutline, IoSearch } from "react-icons/io5";
import { useEffect, useState } from "react";

import ActiveFilters from "../components/modals/Filters/ActiveFilters";
import AddPigeonForm from "../components/modals/AddPigeonForm/AddPigeonForm";
import ChildFilter from "../models/Filter/ChildFilter";
import { FilterName } from "../models/enums/FilterName";
import Filters from "../components/modals/Filters/Filters";
import { IBaseFilter } from "../models/Filter/IBaseFilter";
import ListItem from "../components/ListItem/ListItem";
import { ListItemAction } from "../components/ListItem/IListItem";
import ParentFilter from "../models/Filter/ParentFilter";
import Text from "../components/Text/Text";
import { getPigeons } from "../services/PigeonService";
import { getText } from "../locales/initI18n";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useTheme } from "styled-components";
import withNavbar from "../hoc/withNavbar/withNavbar";

const Pigeons = () => {
  const theme = useTheme();
  const [isFiltersModal, setIsFiltersModal] = useState(false);
  const [filters, setFilters] = useState<IBaseFilter[]>([]);
  const [rawFilters, setRawFilters] = useState<string>("");
  const [isAddForm, setIsAddForm] = useState(false);
  const navigate = useNavigate();

  const getAll = () => {
    return getPigeons(rawFilters);
  };

  const { data: pigeons, refetch } = useQuery({
    queryKey: [`pigeons`, rawFilters],
    queryFn: () => getAll(),
  });

  const onSetFilters = (filters: IBaseFilter[]) => {
    setIsFiltersModal(false);
    setRawFilters(new ParentFilter(filters).transform());
    setFilters(filters);
  };

  const onAfterAddPigeon = () => {
    setIsAddForm(false);
    refetch();
  };

  useEffect(() => {
    refetch();
  }, []);

  return (
    <>
      <Filters
        isVisible={isFiltersModal}
        onClose={() => setIsFiltersModal(false)}
        onConfirm={onSetFilters}
        title={getText("Pigeons.view.SearchPigeons.title")}
        filters={[
          new ChildFilter(FilterName.Name, "", filters),
          new ChildFilter(FilterName.Ring, "", filters),
        ]}
      />
      {isAddForm && (
        <AddPigeonForm
          isVisible={true}
          onClose={() => setIsAddForm(false)}
          onConfirm={onAfterAddPigeon}
        />
      )}
      <VBox position="relative" overflowY="hidden">
        <VBox>
          <Text
            color={theme.palette.Grey90}
            Margin="40px 20px 0 20px"
            type="h3"
            size="large"
            weight="bold"
          >
            {getText("Pigeons.view.title")}
          </Text>
        </VBox>
        <VBox Margin="0 0 10px 0">
          <ListItem
            title={getText("Pigeons.view.AddPigeon.title")}
            description={getText("Pigeons.view.AddPigeon.description")}
            rIcon={<IoPaperPlaneOutline color={theme.palette.White} />}
            action={ListItemAction.Success}
            onClick={() => setIsAddForm(true)}
          />
          <ListItem
            darkest
            title={getText("Pigeons.view.SearchPigeons.title")}
            description={getText("Pigeons.view.SearchPigeons.description")}
            rIcon={<IoSearch size="12px" color={theme.palette.Grey80} />}
            action={ListItemAction.Secondary}
            onClick={() => setIsFiltersModal(true)}
          >
            <ActiveFilters filters={filters} />
          </ListItem>
        </VBox>
        <VBox overflowY="scroll">
          {pigeons?.map((pigeon, index) => (
            <ListItem
              darkest={index % 2 === 0}
              padding="20px"
              key={pigeon.id}
              title={pigeon.name}
              description={pigeon.ring}
              onClick={() => navigate(`${pigeon.id}`)}
            ></ListItem>
          ))}
        </VBox>
      </VBox>
    </>
  );
};

export default withNavbar(Pigeons);
