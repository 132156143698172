import { FemaleIcon, MaleIcon, UnknownIcon } from "./SexIcons";

import { PigeonSexEnum } from "../../../models/enums/PigeonSexEnum";
import SelectOption from "../../../models/SelectOption";

const SexOptions = [
  new SelectOption({
    key: PigeonSexEnum.Male,
    text: "Samiec",
    icon: "male",
  }),
  new SelectOption({
    key: PigeonSexEnum.Female,
    text: "Samica",
    icon: "female",
  }),
  new SelectOption({
    key: PigeonSexEnum.Unknown,
    text: "Nieznana",
    icon: "incident",
  }),
];

export const getInitialSex = (current?: PigeonSexEnum) => {
  const option = SexOptions.find((option) => option.key === current);
  if (option) return option;
  return SexOptions[2];
};

export default SexOptions;
