import { useEffect, useRef, useState } from "react";

import ChildFilter from "../../../models/Filter/ChildFilter";
import { ChoosePigeonProps } from "./IChoosePigeon";
import Debounce from "../../../utils/Debounce";
import { FilterName } from "../../../models/enums/FilterName";
import HalfModal from "../../HalfModal/HalfModal";
import ListItem from "../../ListItem/ListItem";
import ParentFilter from "../../../models/Filter/ParentFilter";
import { ParentFilterOperator } from "../../../models/Filter/ParentFilterOperator";
import TextInput from "../../TextInput";
import { VBox } from "../../../styles/Flexbox.styled";
import { getPigeons } from "../../../services/PigeonService";
import { useQuery } from "@tanstack/react-query";

const ChoosePigeonDialog = ({
  isVisible,
  onClose,
  onConfirm,
  initFilter = new ParentFilter([]),
  title = "Choose pigeon",
  description = "You can search for a pigeon by name or ring number.",
}: ChoosePigeonProps) => {
  const [search, setSearch] = useState<string>("");
  const [rawFilters, setRawFilters] = useState<string>("");

  const onSearch = (value: string) => {
    setSearch(value);
    debounce.current.exec(value);
  };

  const handleFilters = (value: string) => {
    if (!value) {
      setRawFilters(initFilter.transform());
      return;
    }

    const parent = new ParentFilter(
      [
        new ParentFilter(
          [...initFilter.Filters, new ChildFilter(FilterName.Name, value)],
          ParentFilterOperator.And
        ),
        new ParentFilter(
          [...initFilter.Filters, new ChildFilter(FilterName.Ring, value)],
          ParentFilterOperator.And
        ),
      ],
      ParentFilterOperator.Or
    );

    setRawFilters(parent.transform());
  };
  const debounce = useRef<Debounce>(new Debounce(handleFilters, 500));

  const getAll = () => {
    return getPigeons(rawFilters);
  };

  const { data: pigeons } = useQuery({
    queryKey: [`pigeons`, rawFilters],
    queryFn: () => getAll(),
  });

  useEffect(() => {
    if (!isVisible) return;
    setRawFilters(initFilter.transform());
  }, [initFilter, isVisible]);

  if (!isVisible) return null;

  return (
    <HalfModal
      visible={isVisible}
      onClose={onClose}
      title={title}
      description={description}
    >
      <TextInput
        placeholder="Search for a pigeon"
        onChange={(e) => onSearch(e)}
        value={search}
      >
        Search
      </TextInput>
      <VBox overflowY="scroll" gap="10px" Margin="10px 0 0 0">
        {pigeons?.map((pigeon) => (
          <ListItem
            key={pigeon.id}
            title={pigeon.name}
            description={pigeon.ring}
            darkest
            borderRadius="10px"
            onClick={() => onConfirm(pigeon)}
          />
        ))}
      </VBox>
    </HalfModal>
  );
};

const ChoosePigeon = (props: ChoosePigeonProps) => {
  if (!props.isVisible) return null;
  return <ChoosePigeonDialog {...props} />;
};

export default ChoosePigeon;
