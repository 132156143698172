import { ITag } from "./Tag";
import { IUser } from "../services/IUserService";
import { PigeonColorEnum } from "./enums/PigeonColorEnum";
import PigeonNoteBase from "./PigeonNoteBase";
import { PigeonSexEnum } from "./enums/PigeonSexEnum";
import { PigeonStateEnum } from "./enums/PigeonStateEnum";
import PigeonAchievement from "./PigeonAchievement";

export default class Pigeon extends PigeonNoteBase {
  public ring!: string;
  public breed!: string;
  public eyeColor!: string;
  public ownerName!: string;
  public hatchingDate?: Date;
  public year?: number;
  public state!: PigeonStateEnum;
  public sex!: PigeonSexEnum;
  public color!: PigeonColorEnum;
  public createdUser!: IUser;
  public createdUserId!: number;
  public owner!: IUser | null;
  public ownerId!: number;
  public dovecote!: ITag;
  public dovecoteId!: number;
  public father!: Pigeon;
  public fatherId!: number;
  public mother!: Pigeon;
  public motherId!: number;
  public deathDate!: Date;
  public deathCause!: string;
  public motherOf: Pigeon[] = [];
  public fatherOf: Pigeon[] = [];
  public achievements: PigeonAchievement[] = [];

  override fillData(data: any): this {
    super.fillData(data);
    this.year = this.year ? Number(this.year) : undefined;
    if (this.father) {
      this.father = new Pigeon().fillData(this.father);
    }
    if (this.mother) {
      this.mother = new Pigeon().fillData(this.mother);
    }
    if (this.achievements) {
      this.achievements = this.achievements.map((achievement: any) =>
        new PigeonAchievement().fillData(achievement)
      );
    }
    if (this.motherOf.length > 0) {
      this.motherOf = this.motherOf.map((pigeon: any) =>
        new Pigeon().fillData(pigeon)
      );
    }
    if (this.fatherOf.length > 0) {
      this.fatherOf = this.fatherOf.map((pigeon: any) =>
        new Pigeon().fillData(pigeon)
      );
    }

    return this;
  }

  update(data: Pigeon) {
    this.name = data.name;
    this.sex = data.sex;
    this.ring = data.ring;
    this.breed = data.breed;
    this.eyeColor = data.eyeColor;
    this.ownerName = data.ownerName;
    this.hatchingDate = data.hatchingDate;
    this.year = data.year;
    this.state = data.state;
    this.color = data.color;
    this.createdUser = data.createdUser;
    this.owner = data.owner;
    this.dovecote = data.dovecote;
    this.father = data.father;
    this.mother = data.mother;
    this.deathDate = data.deathDate;
    this.deathCause = data.deathCause;
    this.motherOf = data.motherOf;
    this.fatherOf = data.fatherOf;

    return this;
  }

  public removeFather() {
    this.father = undefined as unknown as Pigeon;
    this.fatherId = undefined as unknown as number;
  }

  public removeMother() {
    this.mother = undefined as unknown as Pigeon;
    this.motherId = undefined as unknown as number;
  }

  public get fatherOfPigeons(): Pigeon[] {
    return this.fatherOf || [];
  }

  public get motherOfPigeons(): Pigeon[] {
    return this.motherOf || [];
  }

  public get OwnerName(): string {
    if (this.ownerName) {
      return this.ownerName;
    }
    if (this.owner) {
      return this.owner.firstName + " " + this.owner.lastName;
    }
    return this.createdUser.firstName + " " + this.createdUser.lastName;
  }

  public get ReadableSex(): string {
    switch (this.sex) {
      case PigeonSexEnum.Female:
        return "Female";
      case PigeonSexEnum.Male:
        return "Male";
      default:
        return "Unknown";
    }
  }

  public get ReadableState(): string {
    switch (this.state) {
      case PigeonStateEnum.Active:
        return "Active";
      case PigeonStateEnum.Dead:
        return "Dead";
      case PigeonStateEnum.Borrowed:
        return "Borrowed";
      case PigeonStateEnum.Lost:
        return "Lost";
      case PigeonStateEnum.Sold:
        return "Sold";
      case PigeonStateEnum.Unknown:
        return "Unknown";
      default:
        return "Unknown";
    }
  }

  public get ReadableColor(): string {
    switch (this.color) {
      case PigeonColorEnum.Black:
        return "Black";
      case PigeonColorEnum.Blue:
        return "Blue";
      case PigeonColorEnum.Red:
        return "Red";
      case PigeonColorEnum.White:
        return "White";
      case PigeonColorEnum.FawnBlack:
        return "Fawn Black";
      case PigeonColorEnum.FawnRed:
        return "Fawn Red";
      case PigeonColorEnum.Fawn:
        return "Fawn";
      case PigeonColorEnum.Dark:
        return "Dark";
      case PigeonColorEnum.Grey:
        return "Grey";
      case PigeonColorEnum.SpottedBlue:
        return "Spotted Blue";
      case PigeonColorEnum.SpottedRed:
        return "Spotted Red";
      case PigeonColorEnum.SpottedDark:
        return "Spotted Dark";
      case PigeonColorEnum.SpottedGrey:
        return "Spotted Grey";
      case PigeonColorEnum.SpottedVaricolouredBlack:
        return "Spotted Varicoloured Black";
      case PigeonColorEnum.SpottedVaricolouredBlue:
        return "Spotted Varicoloured Blue";
      case PigeonColorEnum.SpottedVaricolouredDark:
        return "Spotted Varicoloured Dark";
      case PigeonColorEnum.SpottedVaricolouredFawn:
        return "Spotted Varicoloured Fawn";
      case PigeonColorEnum.SpottedVaricolouredRed:
        return "Spotted Varicoloured Red";
      case PigeonColorEnum.VaricolouredBlack:
        return "Varicoloured Black";
      case PigeonColorEnum.VaricolouredBlue:
        return "Varicoloured Blue";
      case PigeonColorEnum.VaricolouredDark:
        return "Varicoloured Dark";
      case PigeonColorEnum.VaricolouredFawn:
        return "Varicoloured Fawn";
      case PigeonColorEnum.VaricolouredRed:
        return "Varicoloured Red";
      case PigeonColorEnum.VaricolouredFawnBlack:
        return "Varicoloured Fawn Black";
      case PigeonColorEnum.VaricolouredFawnRed:
        return "Varicoloured Fawn Red";
      case PigeonColorEnum.VaricolouredGrey:
        return "Varicoloured Grey";
      default:
        return "Unknown";
    }
  }
  prepareForSave(): any {
    this.year = !this.year ? undefined : this.year;
    this.hatchingDate = !this.hatchingDate
      ? undefined
      : new Date(this.hatchingDate);
  }
}
