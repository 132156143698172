import { PigeonColorEnum } from "../../../models/enums/PigeonColorEnum";
import SelectOption from "../../../models/SelectOption";

const ColorOptions = [
  new SelectOption({
    key: PigeonColorEnum.White,

    text: "Biały",
  }),
  new SelectOption({
    key: PigeonColorEnum.Black,

    text: "Czarny",
  }),
  new SelectOption({
    key: PigeonColorEnum.Blue,

    text: "Niebieski",
  }),
  new SelectOption({
    key: PigeonColorEnum.Dark,

    text: "Ciemny",
  }),
  new SelectOption({
    key: PigeonColorEnum.Fawn,

    text: "Płowy",
  }),
  new SelectOption({
    key: PigeonColorEnum.Red,

    text: "Czerwony",
  }),
  new SelectOption({
    key: PigeonColorEnum.Grey,

    text: "Szpakowaty",
  }),
  new SelectOption({
    key: PigeonColorEnum.FawnRed,

    text: "Płowy czerwony",
  }),
  new SelectOption({
    key: PigeonColorEnum.FawnBlack,

    text: "Płowy czarny",
  }),
  new SelectOption({
    key: PigeonColorEnum.SpottedGrey,

    text: "Szpakowaty nakrapiany",
  }),
  new SelectOption({
    key: PigeonColorEnum.SpottedBlue,

    text: "Niebieski nakrapiany",
  }),
  new SelectOption({
    key: PigeonColorEnum.SpottedRed,

    text: "Czerwony nakrapiany",
  }),
  new SelectOption({
    key: PigeonColorEnum.SpottedDark,

    text: "Ciemny nakrapiany",
  }),
  new SelectOption({
    key: PigeonColorEnum.VaricolouredBlue,

    text: "Niebieski pstrokaty",
  }),
  new SelectOption({
    key: PigeonColorEnum.VaricolouredDark,

    text: "Ciemny pstrokaty",
  }),
  new SelectOption({
    key: PigeonColorEnum.VaricolouredBlack,

    text: "Czarny pstrokaty",
  }),
  new SelectOption({
    key: PigeonColorEnum.VaricolouredRed,

    text: "Czerwony pstrokaty",
  }),
  new SelectOption({
    key: PigeonColorEnum.VaricolouredFawn,

    text: "Płowy pstrokaty",
  }),
  new SelectOption({
    key: PigeonColorEnum.VaricolouredGrey,

    text: "Szpakowaty pstrokaty",
  }),
  new SelectOption({
    key: PigeonColorEnum.VaricolouredFawnRed,

    text: "Płowy czerwony pstrokaty",
  }),
  new SelectOption({
    key: PigeonColorEnum.VaricolouredFawnBlack,

    text: "Płowy czarny pstrokaty",
  }),
  new SelectOption({
    key: PigeonColorEnum.SpottedVaricolouredBlue,

    text: "Niebieski pstrokaty nakrapiany",
  }),
  new SelectOption({
    key: PigeonColorEnum.SpottedVaricolouredDark,

    text: "Ciemny pstrokaty nakrapiany",
  }),
  new SelectOption({
    key: PigeonColorEnum.SpottedVaricolouredBlack,

    text: "Czarny pstrokaty nakrapiany",
  }),
  new SelectOption({
    key: PigeonColorEnum.SpottedVaricolouredRed,

    text: "Czerwony pstrokaty nakrapiany",
  }),
  new SelectOption({
    key: PigeonColorEnum.SpottedVaricolouredFawn,
    text: "Płowy pstrokaty nakrapiany",
  }),
];

export const getInitialColor = (current?: PigeonColorEnum) => {
  const option = ColorOptions.find((option) => option.key === current);
  if (option) return option;
  return ColorOptions[0];
};

export default ColorOptions;
