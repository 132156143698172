import { Button, ButtonType } from "../../../styles/Button.styled";
import { FriendsProps, IFriend } from "./IFriends";
import { HBox, VBox } from "../../../styles/Flexbox.styled";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";

import HalfModal from "../../HalfModal/HalfModal";
import ListItem from "../../ListItem/ListItem";
import { ListItemAction } from "../../ListItem/IListItem";
import PageOptions from "../../../models/PageOptions";
import { UserType } from "../../../models/enums/UserType";
import { getFriends } from "../../../services/FollowerService";
import { getText } from "../../../locales/initI18n";

const Friends = ({
  shared = [],
  multiSelect = true,
  ...props
}: FriendsProps) => {
  const queryFn = async (options = new PageOptions()) => {
    const friends = await getFriends(options);
    if (shared) {
      shared.forEach((sh) => {
        const friend = friends.data.find((fr) => fr.id === sh);
        if (friend) friend.state = true;
      });
    }
    return friends;
  };

  const shareFlight = async () => {
    const farmers = friends?.filter((f) => f.state);
    props.onConfirm(farmers || []);
  };

  const { data, fetchNextPage } = useInfiniteQuery({
    queryKey: [`friends`],
    queryFn: ({ pageParam = new PageOptions() }) => queryFn(pageParam),
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.meta.isNext) {
        return new PageOptions(lastPage.meta.page + 1);
      }
    },
    enabled: props.isVisible,
    cacheTime: 0,
  });

  const onSelect = (friend: IFriend) => {
    if (multiSelect) {
      friend.state = !friend.state;
      return;
    }
    friends?.forEach((f) => {
      f.state = false;
    });
    friend.state = true;
  };

  const friends = data?.pages.flatMap((page) => page.data);
  const lastMeta = data?.pages[data.pages.length - 1].meta;

  return (
    <HalfModal
      onClose={props.onClose}
      visible={props.isVisible}
      title={
        multiSelect
          ? getText("Friends.ModalTitleFriends")
          : getText("Friends.ModalTitleFriend")
      }
      Padding="0"
    >
      <VBox Padding="20px 0 0 0" overflowY="scroll">
        {friends?.map((friend, index) => (
          <ListItem
            visible={
              props.onlyPlanners ? friend.type === UserType.Planner : true
            }
            key={`friend-${friend.id}`}
            title={friend.username}
            description=""
            action={ListItemAction.Checkbox}
            isChecked={friend.state}
            onClick={() => onSelect(friend)}
            darkest={index % 2 === 0}
          />
        ))}
        <HBox
          visible={lastMeta?.isNext}
          justifyContent="center"
          Margin="10px 0 0 0"
        >
          <Button
            padding="0 20px"
            buttonType={ButtonType.Ghost}
            onClick={() => {
              fetchNextPage();
            }}
          >
            {getText("ShowMore")}
          </Button>
        </HBox>
      </VBox>
      <HBox Margin="20px 20px">
        <Button
          buttonType={ButtonType.Success}
          height="56px"
          width="100%"
          onClick={shareFlight}
        >
          {multiSelect
            ? getText("Friends.ShareWithFriends")
            : getText("Friends.ChooseFriend")}
        </Button>
      </HBox>
    </HalfModal>
  );
};

export default Friends;
